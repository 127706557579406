import React from 'react';
import { graphql } from 'gatsby';

import { grid, container, heading } from './location-categories.module.scss';
import { IQueryAllResult } from '../models/query-all-result.model';
import { ILocationCategory } from '../models/location-category.model';
import { getNodes } from '../utils/get-nodes';
import useTranslations from '../hooks/use-translations';

import MainLayout from '../layouts/main-layout';
import Heading from '../components/atoms/heading';
import ThumbnailCardListing from '../components/molecules/thumbnail-card-listing';

interface ILocationCategoriesQueryResults {
    allLocationCategory: IQueryAllResult<ILocationCategory>;
}

interface ILocationCategoriesProps {
    data: ILocationCategoriesQueryResults;
}

const LocationCategories: React.FC<ILocationCategoriesProps> = ({ data }) => {
    const t = useTranslations('LocationCategories');
    const locationCategories = getNodes(data.allLocationCategory);

    return (
        <MainLayout additionalSearch={{ type: 'location' }}>
            <div className={grid}>
                <div className={container}>
                    <Heading Tag={'h1'} numberOfStyle={2} className={heading}>
                        {t.heading}
                    </Heading>
                    <ThumbnailCardListing cards={locationCategories} isFirstItemFeatured={true} />
                </div>
            </div>
        </MainLayout>
    );
};

export const query = graphql`
    query ($locationIds: [Int]) {
        allLocation(
            filter: { locationId: { in: $locationIds } }
            sort: { fields: sequence, order: ASC }
        ) {
            edges {
                node {
                    ...locationFields
                }
            }
        }

        allLocationCategory(sort: { fields: sequence, order: ASC }) {
            edges {
                node {
                    ...locationCategoryFields
                }
            }
        }
    }
`;

export default LocationCategories;
